@import "../../global.scss";
.contact {
  background-color: #fff;
  height: 100vh;
  display: flex;
  @include Mobile {
    flex-direction: column;
  }
  .left {
    flex: 0.55;
    background-image: url("../../assets/handshake.svg");
    background-size: cover;
    @include Tablet {
      display: none;
    }
    @include Mobile {
      display: flex;
      flex: 0.4;
      align-items: center;
      justify-content: center;
    }

    img {
      height: 100%;
    }
  }
  .right {
    flex: 0.45;
    @include Tablet {
      flex: 1;
      align-items: center;
      justify-content: center;
    }
    @include Mobile {
      flex: 0.6;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h2 {
      @include laptop {
        font-size: 40px;
      }
      font-size: 60px;
      color: $secondaryColor;
    }
    form {
      // background-color: red;
      width: 90%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      // background-color: red;
      @include Tablet {
        align-items: center;
      }
      input {
        @include laptop {
          font-size: 16px;
        }

        @include bigTablet {
          width: 250px;
        }
        @include Tablet {
          width: 350px;
        }
        @include Mobile {
          width: 85%;
          margin-bottom: 10px;
        }
        width: 90%;
        height: 40px;
        font-size: 18px;
        border-color: $secondaryColor;
        border-radius: 8px;
        padding: 8px 16px;
        border: 2px solid $secondaryColor;
        &:focus {
          outline: 1px solid $secondaryColor;
        }
      }

      textarea {
        @include bigTablet {
          width: 250px;
        }
        @include Tablet {
          width: 350px;
        }
        @include Mobile {
          min-width: 85%;
          margin-bottom: 10px;
          height: 90px;
        }
        min-width: 90%;
        max-width: 200px;
        min-height: 160px;
        max-height: 300px;
        font-size: 18px;
        border-color: $secondaryColor;
        border-radius: 10px;
        padding: 8px 16px;
        border: 2px solid $secondaryColor;

        &:focus {
          outline: 1px solid $secondaryColor;
        }
      }

      button {
        min-width: 96.5%;
        height: 55px;
        font-size: 18px;
        color: #fff;
        background-color: $secondaryColor;
        border: 2px solid $secondaryColor;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background-color: $mainColor;
          border: 2px solid $mainColor;
          color: white;
        }
        @include bigTablet {
          width: 250px;
        }
        @include Tablet {
          width: 150px;
        }
        @include Mobile {
          width: 40%;
        }
      }
      span {
        color: #54e346;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
