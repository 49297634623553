@import "../../global.scss";
.portfolio {
  background-color: $mainColor;
  position: relative;
  height: 100vh;

  // background-color: red;
  @include Mobile {
    flex-direction: column;
    min-height: 100vh;
  }

  .portfolioHeader {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 100px;
    color: #fff;
    font-size: 28px;
    font-family: serif;
    // border: 1px solid white;
    // padding-top: 90px;
    h1 {
      margin-top: 135px;
    }
    @include Mobile {
      h1 {
        margin-top: 135px;
        font-size: 28px;
        letter-spacing: 1.2px;
      }
    }
  }

  .arrow {
    font-size: 64px;
    font-weight: 300;
    position: absolute;
    top: 50%;
    cursor: pointer;
    color: #fff;
    @include laptop {
      font-size: 58px;
    }

    @include Mobile {
      font-size: 28px;
      color: #fff;
      display: none;
    }
    &.left {
      left: 2%;
      @include laptop {
        left: 2%;
      }
      @include miniLaptop {
        left: 0;
      }
      @include bigTablet {
        left: 0;
      }
      @include Tablet {
        left: 0;
      }
      @include Mobile {
        left: 0;
      }
    }
    &.right {
      right: 2%;
      @include laptop {
        right: 2%;
      }
      @include miniLaptop {
        right: 0;
      }
      @include bigTablet {
        right: 0;
      }
      @include Tablet {
        right: 0;
      }
      @include Mobile {
        right: 0;
      }
    }
  }

  .slider {
    // background-color: red;
    top: 25%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    transition: all 0.8s ease-out;

    @include laptop {
      top: 25%;
      height: 475px;
    }
    @include Mobile {
      top: 160px;
      height: 70%;
    }

    .container {
      width: 100vw;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include miniLaptop {
        height: 100%;
        flex-direction: column;
        margin-bottom: 20px;
      }

      .item {
        width: 70%;
        height: 100%;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include laptop {
          width: 70%;
          height: 475px;
        }
        @include Tablet {
          width: 450px;
        }
        @include miniLaptop {
          width: 85vw;
          height: 100%;
          border-radius: 15px;
          flex-direction: column-reverse;
        }

        .left {
          flex: 5;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          @include Mobile {
            flex: 0.65;
            align-items: center;
          }

          .leftContainer {
            width: 90%;
            height: 75%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include Mobile {
              height: 100%;
              justify-content: space-evenly;
            }
            // background-color: red;

            h2 {
              font-size: 36px;
              font-weight: 500;
              margin: 0;
              @include laptop {
                font-size: 32px;
              }
              @include Tablet {
                font-size: 20px;
              }
              @include Mobile {
                font-size: 20px;
                margin-bottom: 10px;
              }
            }
            p {
              @include laptop {
                font-size: 18px;
                line-height: 28px;
              }
              @include Tablet {
                font-size: 16px;
              }
              @include Mobile {
                font-size: 16px;
              }
            }
            .btnContainer {
              width: 95%;
              display: flex;
              justify-content: space-between;
              @include Mobile {
                width: 100%;
              }

              .btnDemo {
                background-color: $secondaryColor;
                color: white;
                transition: all 0.2s ease;
                &:hover {
                  background-color: #fff;
                  color: $mainColor;
                }
              }
              .btnCode {
                background-color: #fff;
                color: $secondaryColor;
                transition: all 0.2s ease;
                &:hover {
                  background-color: $secondaryColor;
                  color: white;
                }
              }
              .btn {
                font-size: 18px;
                font-weight: 600;
                text-decoration: none;
                border: 1px solid $secondaryColor;
                width: 160px;
                height: 50px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include Mobile {
                  width: 135px;
                  height: 45px;
                }
              }
              // .btn:link,
              // .btn:visited {
              //   font-size: 18px;
              //   font-weight: 700;
              //   width: 40%;
              //   text-decoration: none;
              //   border-radius: 8px;
              //   -webkit-transition: background-color 0.2s, border 0.2s,
              //     color 0.2s;
              //   transition: background-color 0.2s, border 0.2s, color 0.2s;
              //   padding: 14px 0px;
              //   text-align: center;
              //   @include laptop {
              //     font-size: 20px;
              //   }
              //   @include Tablet {
              //     font-size: 12px;
              //   }
              //   @include Mobile {
              //     font-size: 16px;
              //     padding: 14px 8px;
              //   }
              // }
              // .btn:link,
              // .btn:visited {
              //   border: 1px solid $mainColor;
              //   color: $mainColor;
              //   background-color: #fff;
              // }
              // .btn:hover,
              // .btn:active,
              // .btn:focus {
              //   background-color: $secondaryColor;
              //   color: #fff;
              // }
            }
          }
        }
        .right {
          flex: 8;
          height: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          // border: 1px solid black;
          @include laptop {
            flex: 5;
            height: 75%;
          }
          @include Tablet {
            flex: 4;
            height: 75%;
          }
          @include Mobile {
            width: 90%;

            border-radius: 4px;

            flex: 0.3;
          }

          img {
            width: 425px;
            height: 240px;
            // border-radius: 20px;
            @include Mobile {
              width: 100%;
              height: 175px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}
