@import "../../global.scss";

.intro {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  @include Mobile {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // background-color: red;
  }

  .left {
    flex: 0.45;
    height: calc(100vh - 70px);
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    @include Mobile {
      flex: 0.35;
      margin-top: 80px;
      // background-color: red;
    }

    .imgContainer {
      width: 300px;
      height: 466px;
      @include Mobile {
        width: 175px;
        height: 271px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .right {
    flex: 0.55;
    height: calc(100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    @include Mobile {
      justify-content: flex-start;
      flex: 0.65;
    }
    .wrapper {
      width: 100%;
      height: 50%;
      padding-left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      @include Mobile {
        align-items: center;
        width: 100%;
        height: 250px;
      }

      h2 {
        // height: 75px;
        font-size: 64px;
        color: #5a5a5a;
        // background-color: red;
        @include laptop {
          font-size: 36px;
        }
        @include Tablet {
          font-size: 20px;
        }
        @include Mobile {
          font-size: 22px;
        }

        span {
          font-size: inherit;
          color: #5a5a5a;
        }
        .ityped-cursor {
          animation: blink 1s infinite;
        }
        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
      h1 {
        font-size: 54px;
        font-weight: 900;
        color: #5a5a5a;
        @include Mobile {
          font-size: 22px;
        }
      }
    }
    .expand {
      font-size: 75px;
      position: absolute;
      bottom: 0px;
      animation: arrowBlink 1s infinite;
      color: $secondaryColor;
      @include Mobile {
        width: 100%;
        bottom: 34px;
      }
    }
    @keyframes arrowBlink {
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
