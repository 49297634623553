@import "../../global.scss";
.skills {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;

  .skills-top-container {
    // border: 1px solid black;
    height: 175px;
    margin-top: 70px;
    margin-bottom: 50px;
    @include Mobile {
      margin-top: 50px;
      margin-bottom: 0px;
    }
    h1 {
      // background-color: red;
      height: 75px;
      font-size: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-bottom: 50px;
      // background-color: red;
      @include laptop {
        font-size: 40px;
      }
      @include Mobile {
        font-size: 24px;
        height: 40px;
      }
      color: $mainColor;
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ul {
      // background-color: red;
      width: 50%;
      height: 150px;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include Mobile {
        width: 95%;
        font-size: 12px;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 100px;

        // margin-bottom: 15px;
        // margin-top: 25px;
      }

      li {
        font-size: 22px;
        height: 40px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        padding: 7px;
        border-radius: 10px;
        cursor: pointer;
        border: 1px solid $secondaryColor;
        color: $secondaryColor;
        @include laptop {
          font-size: 20px;
        }
        @include Mobile {
          font-size: 14px;
          width: 60px;
          height: 30px;
          margin: 0px;
        }

        &.active {
          background-color: $secondaryColor;
          color: #fff;
          transition: all 0.8s ease;
        }
      }
    }
  }
  .container {
    width: 70%;
    min-height: 325px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 70px;
    @include laptop {
      min-height: 300px;
    }
    flex-wrap: wrap; // when widt has been reached go to next line.
    @include Mobile {
      width: 100%;
      height: 100%;
      margin-bottom: 70px;
    }
    .item-container {
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include Mobile {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .item {
        width: 200px;
        height: 100px;
        border-radius: 10px;
        border: 1px solid lightgrey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        animation: popup 0.7s ease;
        padding-top: 33px;
        margin: 16px 16px;

        @include laptop {
          width: 180px;
          height: 100px;
          justify-content: flex-start;
          align-items: center;
          padding-top: 20px;
          margin: 8px 16px;
        }
        @include Mobile {
          width: 100px;
          height: 60px;
          margin: 0px 8px;
        }

        h3 {
          font-size: 20px;
          @include laptop {
            font-size: 14px;
          }
          @include Mobile {
            margin-top: 8px;
            font-size: 12px;
          }
        }
        img {
          width: 25%;
          height: 40%;
        }
      }
    }

    @keyframes popup {
      0% {
        //transform:rotateZ(330deg);
        transform: scale(0.25);
      }

      100% {
        //transform: rotateZ(360deg);
        transform: scale(1);
      }
    }
  }
}
