@import "../../global.scss";
.topbar {
  width: 100%;
  height: 70px;
  background-color: #fff;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: all 1s ease;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        margin-right: 40px;
        color: $darkBlue;
        transition: all 1.2s ease;
        @include Mobile {
          font-size: 30px;
        }
      }
      .itemContainer {
        display: flex;
        align-items: center;
        margin-right: 30px;

        @include Mobile {
          display: none;
        }
      }
      .icon {
        font-size: 25px;
        margin-right: 5px;
      }
      span {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .right {
    .ham {
      width: 32px;
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      span {
        width: 100%;
        height: 3px;
        background-color: $mainColor;
        transform-origin: left;
        transition: all 1.2s ease;
      }
    }
  }
  &.active {
    background-color: $mainColor;
    color: #fff;

    .wrapper {
      .left {
        .logo {
          color: #fff;
        }
      }
    }

    .ham {
      span {
        &:first-child {
          background-color: #fff;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: #fff;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
